/* eslint-disable jsx-a11y/alt-text */
import Credentials from "../credentials/Credentials";

function ContCred() {
  return (
    <div className="contCred">
      <div
        className="container more-about"
        id="#credentials"
        style={{ marginTop: "1rem", marginBottom: "3rem" }}
      >
        <h5 style={{ marginTop: "3rem", marginBottom: "2rem" }}>
          Credentials —
        </h5>
        <Credentials />
      </div>

      {/*contributions */}
      <hr style={{ marginTop: "1rem" }} />
      <div
        className="container more-about"
        style={{ marginTop: "rem", marginBottom: "8rem" }}
      >
        <h5 style={{ marginTop: "3rem", marginBottom: "2rem" }}>
          Contributions —
        </h5>
        <h4 className="mb-4">
          Writings:{" "}
          <span>
            <a
              href="https://hrahatsharif.medium.com/mvp-understand-it-first-7dd6fc82d80c"
              target="__blank"
              className="text-primary"
            >
              MVP - Understand it first
            </a>
            &nbsp; and more...
          </span>
        </h4>
        <h4>
          On forum:{" "}
          <a
            href="https://ux.stackexchange.com/users/142295/hasan-sharif"
            className="text-primary"
            target="__blank"
          >
            Helping UX folks on Stackexchange
          </a>
        </h4>
      </div>
    </div>
  );
}

export default ContCred;
