//import seventhcover from "../../img/seven/seventhcover.webp";
//import seventhintro from "../../img/seven/seventhintro.webp";
//import seventhdeckqr from "../../img/seven/seventhdeckqr.png";
//import seventhfeedbackall from "../../img/seven/seventhfeedbackall.webp";
//import seventhanalyticsdoc1 from "../../img/seven/seventhanalyticsdoc1.png";

//last serial: 1.6

const ProjectSeventhData = [
  //  {
  //    _ID: "sdqme01.7",
  //    projectImg: seventhcover,
  //    projectHeading: "Hadith Collection (All in One)",
  //    projectSubTitle: (
  //      <>
  //        In GTAF, I was directly involved in making a product retrospective
  //        report for the feature "Robust Image Sharing" of one of the mostly used
  //        Hadith apps&nbsp;
  //        <a
  //          href="https://play.google.com/store/apps/details?id=com.greentech.hadith"
  //          target="__blank"
  //          className="text-primary"
  //        >
  //          Hadith Android.
  //        </a>{" "}
  //        That feature was released as a MVP version and intentionally placed the
  //        feature's CTA inside the navigation drawer. What was the success metric?
  //        Let's deep dive in!
  //      </>
  //    ),
  //    slug: "hadith-gtaf",
  //    based: "UK",
  //    tag: "Islamic Knowledge",
  //    timeline: <span> 06/21/2023 - 08/18/2023</span>,
  //    projectDetails: (
  //      <div className="row">
  //        {/*intro */}
  //        <div className="col-md-12 mb-5">
  //          <h3>
  //            In GTAF, I was directly involved in making a product retrospective
  //            report for the feature "Robust Image Sharing" of one of the mostly
  //            used Hadith apps&nbsp;
  //            <a
  //              href="https://play.google.com/store/apps/details?id=com.greentech.hadith"
  //              target="__blank"
  //              className="text-primary"
  //            >
  //              Hadith Android.
  //            </a>{" "}
  //            That feature was released as a MVP version and intentionally placed
  //            the feature's CTA inside the navigation drawer.
  //          </h3>
  //          <p
  //            className="text-secondary text-center fw-light mt-5 ps-2 pe-2"
  //            style={{ fontSize: "22px" }}
  //          >
  //            <i>Data you can see here are almost real.</i>
  //          </p>
  //        </div>
  //        <div className="col-md-6 col-12 mt-4">
  //          <h4>
  //            <u>Task goal</u>
  //          </h4>
  //          <p>
  //            To systematically evaluate the performance of the feature "Robust
  //            Image Share", understand user feedback, analyse quantitative data
  //            for broader perspective, identify areas of success and improvement,
  //            collect findings from marketing campaigns, and use these insights to
  //            optimize the feature and enhance the overall user experience.
  //          </p>
  //        </div>
  //        <div className="col-md-6 col-12 mt-4">
  //          <h4>
  //            <u>My role (Associate Product Manager)</u>
  //          </h4>
  //          <ul>
  //            <li>Gather user feedback</li>
  //            <li>Aggregate analytical data</li>
  //            <li>Prepare the document</li>
  //            <li>
  //              Ask for feedback from the stakeholders and take decision
  //              accordingly
  //            </li>
  //          </ul>
  //        </div>
  //        {/*intro screens */}
  //        <div className="col-md-12 text-center mt-5">
  //          <img src={seventhintro} alt="nine-star-intro" className="w-75" />
  //          <p
  //            className="text-center text-secondary mt-1"
  //            style={{ fontSize: "14px" }}
  //          >
  //            Collaboration of a product of 500k+ users
  //          </p>
  //        </div>
  //        {/*last insight */}
  //        <div className="row" style={{ marginTop: "2rem" }}>
  //          {/*The challenge */}
  //          <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
  //            <h3 className="mb-4 fw-bold">Process</h3>
  //            <p style={{ lineHeight: "35px", fontSize: "22px" }}>
  //              <ul>
  //                <li>Analyse User Feedback and Reviews from 4+ sources</li>
  //                <li>
  //                  Search for GTAF Hadith gallery image in Google to get an idea
  //                  if it’s being shared by users on social
  //                </li>
  //                <li>Analyse Analytics Data </li>
  //                <li>Evaluate with Success Metrics defined earlier</li>
  //                <li>Document what went well, didn’t go well, etc.</li>
  //                <li>Share with the team for async feedback</li>
  //              </ul>
  //            </p>
  //          </div>
  //          <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
  //            <h3 className="mb-4 fw-bold">Timeline</h3>
  //            <p style={{ lineHeight: "35px", fontSize: "22px" }}>
  //              Right after the 30% rollout 3/4 days before the final release
  //              which was around 16th June 2024, I have been checking user
  //              feedback if any bug reports were submitted. From 21st June, 100%
  //              rollout was started and the product team ideally takes 1-1.5
  //              months to wrap up a retro. Note that, we needed to create multiple
  //              retro reports at a time.
  //            </p>
  //          </div>
  //          <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
  //            <h3 className="mb-4 fw-bold">With stakeholders</h3>
  //            <p style={{ lineHeight: "35px", fontSize: "22px" }}>
  //              We first shared the presentation in its corresponding Slack
  //              channel where the CEO, CTO, and COO including developers and
  //              designers were open to sharing their feedback. If needed, we asked
  //              the CEO for further steps based on the summary of the retro.
  //            </p>
  //          </div>
  //        </div>
  //        {/*design framework */}
  //        <div className="row" style={{ marginTop: "5rem" }}>
  //          <div className="col-md-7">
  //            <p>
  //              {" "}
  //              <b>How we analyzed all the feedback</b>
  //            </p>
  //            <h3 style={{ lineHeight: "40px" }}>
  //              Our users are always open to submit feedback to the GTAF team
  //              through 4-5 feedback channels. As an associate PM, checking
  //              feedback is a goto task but when it needs to quantify, we sort of
  //              aggregated all quantitative and qualitative feedback into a
  //              central repository.
  //            </h3>
  //          </div>
  //          <div className="col-md-5 align-self-center">
  //            <img
  //              src={seventhfeedbackall}
  //              alt="nine-star-ucd"
  //              className="w-100"
  //            />
  //            <p
  //              className="text-center text-secondary"
  //              style={{ fontSize: "14px", marginTop: "3rem" }}
  //            >
  //              All feedback channels were in the playground
  //            </p>
  //          </div>
  //        </div>
  //        {/*user research - survey > pesona*/}
  //        <div className="row" style={{ marginTop: "10rem" }}>
  //          {/*survey */}
  //          <div className="col-md-12 text-center">
  //            <p>
  //              {" "}
  //              <b>A/B testing - A life saver tool </b>
  //            </p>
  //            <h3 style={{ lineHeight: "35px" }}>
  //              Now that We didn't notice any feedback, but it’s fine as we ran
  //              sort of an A/B test experiment to a small set of users.
  //            </h3>
  //          </div>{" "}
  //          <div className="col-md-12 mt-5 text-center">
  //            <img
  //              src={seventhanalyticsdoc1}
  //              alt="nine-star-persona"
  //              className="w-75 mb-2"
  //            />
  //            <p
  //              className="text-center text-secondary mb-5"
  //              style={{ fontSize: "14px", marginTop: "1rem" }}
  //            >
  //              First step of quantifying data
  //            </p>
  //          </div>
  //          <div className="row" style={{ marginTop: "5rem" }}>
  //            {/*survey */}
  //            <div className="col-md-12 text-center">
  //              <h3 style={{ lineHeight: "35px" }}>Updating soon</h3>
  //            </div>{" "}
  //          </div>
  //          {/*persona */}
  //          {/*<div className="col-md-12" style={{ marginTop: "5rem" }}>
  //            <p>
  //              {" "}
  //              <b>Analysis - Secondary User Research </b>
  //            </p>
  //            <h3 style={{ lineHeight: "40px" }}>
  //              Next, on secondary research phase, I collected fixed customers
  //              data from their hard copy data sheet / diary where they documented
  //              fixed customer's weekly order, debt, contact information, short
  //              feedback etc from which I formed up the user persona
  //              representation of the particular group of our users. To understand
  //              the context of use, I also abstracted "Scenarios of interaction"
  //              of the target users so that It can help make an efficient design
  //              for the mobile users.
  //            </h3>
  //          </div>{" "}
  //          <div className="col-md-12 mt-5 border">
  //            <img src={ninepersona} alt="nine-star-persona " className="w-100" />
  //            <p
  //              className="text-center text-secondary"
  //              style={{ fontSize: "14px", marginTop: "1rem" }}
  //            >
  //              Fixed customer's persona
  //            </p>
  //          </div>
  //          <div className="text-center" style={{ marginTop: "5rem" }}>
  //            <h3 style={{ lineHeight: "40px" }}>
  //              ❝By combining data of exploratory and secondary research, it
  //              helped me thoroughly to make hypotheses-driven design decisions❞
  //            </h3>
  //          </div>
  //        </div>*/}
  //          {/*proto-qr*/}
  //          <div
  //            style={{
  //              marginTop: "10rem",
  //            }}
  //            className="row"
  //          >
  //            <div className="col-md-12 col-12 mb-4">
  //              <h4
  //                style={{
  //                  fontSize: "28px",
  //                  //lineHeight: "50px",
  //                  textAlign: "left",
  //                }}
  //              >
  //                Scan this QR code to view the original deck
  //              </h4>
  //            </div>
  //            <div className="col-md-6 col-12 text-left">
  //              <img src={seventhdeckqr} alt="nine-qr" className="w-50" />
  //              <p className="fw-bold mt-3" style={{ fontSize: "16px" }}>
  //                Scan Me
  //              </p>
  //            </div>
  //          </div>
  //        </div>
  //        <p className="text-center" style={{ marginTop: "5rem" }}>
  //          - A big thank you for the time you spent reading this case study -
  //        </p>
  //      </div>
  //    ),
  //  },
];

export function getProjectSeventhData() {
  return ProjectSeventhData;
}
