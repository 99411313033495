import { useState, useContext, useEffect } from "react";

import { useParams } from "react-router-dom";
import { StateContext } from "../../Context/StateProvider";

function ProductDetails() {
  const [details, setDetails] = useState([]);
  const { slug } = useParams();
  //  console.log("use param slug", slug);

  const { firstProduct } = useContext(StateContext);
  const { secondProduct } = useContext(StateContext);

  useEffect(() => {
    const singleProject = firstProduct.map((project) => {
      //  console.log("slug", slug);
      if (project.slug === slug) return setDetails(project);
    });
  }, [slug]);

  useEffect(() => {
    const singleProject = secondProduct.map((project) => {
      //  console.log("slug", slug);
      if (project.slug === slug) return setDetails(project);
    });
  }, [slug]);

  //  console.log("project", details);

  return (
    <>
      {firstProduct ? (
        <div className="container details" id="#top">
          <div className="row">
            <div className="col-md-12">
              <h2 className="details__heading">{details.projectHeading}</h2>
              <p>Timeline: {details.timeline}</p>
              <p>Based in: {details.based}</p>
              <span className="text-dark details__project-tag">
                Industry: {details.tag}
              </span>

              <div className="details__description">
                {details.projectDetails}
              </div>

              <div
                className="d-flex justify-content-end"
                style={{ marginTop: "10rem" }}
              >
                <a
                  href="https://hasansharif.com"
                  className="btn btn-lg text-success me-4"
                >
                  {" "}
                  <i className="fa fa-home"></i>&nbsp;Home
                </a>
                <a href="#top" className="btn btn-lg">
                  <i className="fa fa-arrow-up"></i>&nbsp;Top
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : secondProduct ? (
        <div className="container-fluid details" id="#top">
          <div className="row">
            <div className="col-md-12">
              <h2 className="details__heading">{details.projectHeading}</h2>
              <p>Timeline: {details.timeline}</p>
              <p>Based in: {details.based}</p>
              <span className="text-dark details__project-tag">
                Industry: {details.tag}
              </span>

              <div className="details__description">
                {details.projectDetails}
              </div>

              <div
                className="d-flex justify-content-end"
                style={{ marginTop: "10rem" }}
              >
                <a
                  href="https://hasansharif.com"
                  className="btn btn-lg text-success me-4"
                >
                  {" "}
                  <i className="fa fa-home"></i>&nbsp;Home
                </a>
                <a href="#top" className="btn btn-lg">
                  <i className="fa fa-arrow-up"></i>&nbsp;Top
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        "No details"
      )}
    </>
  );
}

export default ProductDetails;
