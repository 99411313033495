/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [state, setState] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    window.scrollY > 450 ? setState(true) : setState(false);
  };

  const navbarClass =
    "navbar navbar-expand-lg navbar-light fixed-top bg-transp";

  //  const handleAuth = () => {
  //    if (auth) {
  //      auth.signOut();
  //      navigate("/login");
  //    }
  //  };

  return (
    <nav className={`${navbarClass} ${state ? "navbar-shadow " : ""}`}>
      <div className="container">
        <Link to="/">
          <span
            className="navbar-brand text-light"
            style={{
              color: "#222222",
              WebkitTextStrokeWidth: "1px",
              WebkitTextFillColor: "#ffffff",
              WebkitTextStrokeColor: "#222222",
              fontFamily: "Righteous",
              fontSize: "24px",
            }}
          >
            Hasan <span className="nav-job-role"> UX Designer</span>
          </span>
        </Link>
        <h4 className="nav-center-text" style={{ color: "#222222" }}>
          Port of a user-centered designer
        </h4>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <Link to="/contact">
          <span
            className="navbar-brand text-light"
            style={{
              color: "#222222",
              WebkitTextStrokeWidth: "1px",
              WebkitTextFillColor: "#ffffff",
              WebkitTextStrokeColor: "#222222",
            }}
          >
            Contact 🤝
          </span>
        </Link>
        {/*<div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ms-auto">
            <Link to="/product">
              <li className="nav-item">
                <span className={`nav-link ${state ? "nav-link-black" : ""}`}>
                  Products
                </span>
              </li>
            </Link>

            <Link to="/">
              <li className="nav-item">
                <span className={`nav-link ${state ? "nav-link-black" : ""}`}>
                  Projects
                </span>
              </li>
            </Link>
          </ul>
        </div>*/}
      </div>
    </nav>
  );
};

export default Navbar;
