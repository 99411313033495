import themeinmarketplace from "../../../img/works/themeinmarketplace.png";
import smeandroid from "../../../img/works/smeandroid.png";

{
  /*<a href="https://themeforest.net/item/konstra-construction-template-for-architect-and-construction/23635565?s_rank=1" target="__blank"></a>*/
}

function Left() {
  return (
    <div className="col-12 col-md-4 attention-two-left">
      <a
        href="https://play.google.com/store/apps/details?id=com.sadaqahmadeeasy.app"
        target="__blank"
      >
        <div className="card text-center mt-5 p-0">
          <div className="card-body">
            <h4 className="card-title" style={{ color: "#cdceff" }}>
              <i className="fa fa-heart-o" aria-hidden="true"></i> &nbsp; A
              design creating impact &nbsp;
              <i className="fa fa-heart-o" aria-hidden="true"></i>
            </h4>

            <h4 className="card-text fw-light text-center text-light">
              Founded, Designed, Managed, Deployed
            </h4>

            <div className="card-bottom-img">
              <img
                src={smeandroid}
                alt="facebook-review-for-gtaf.org"
                className="w-50"
              />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
export default Left;
