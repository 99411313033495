//import sme from "../../img/sme/sme.jpeg";
//import smefeb from "../../img/sme/sme-feb.jpg";
//import smejuly from "../../img/sme/sme-july.jpg";
//import smefunnel from "../../img/sme/sme-funnel.jpg";
//import smewireframeone from "../../img/sme/smewireframeone.jpeg";
//import smeusertestingmobile from "../../img/sme/smeusertestingmobile.jpg";
//import smeusertestingdesktop from "../../img/sme/smeusertestingdesktop.jpg";
//import smecompcarddesign from "../../img/sme/smecompcarddesign.jpg";
//import smeprojectcard from "../../img/sme/smeprojectcard.jpg";
//import smebrand from "../../img/sme/smebrand.jpg";
//import smeotherscreensone from "../../img/sme/smeotherscreensone.jpg";
//import smeotherscreenstwo from "../../img/sme/smeotherscreenstwo.jpg";
//import smeresponsive from "../../img/sme/smeresponsive.jpg";
//import smeheatmap from "../../img/sme/smeheatmap.jpeg";
//import smereviews from "../../img/sme/smereviews.jpg";
//import smelifecycle from "../../img/sme/smelifecycle.jpg";
//import smeproductmetric from "../../img/sme/smeproductmetric.jpeg";
//import smeorgincreaserate from "../../img/sme/smeorgincreaserate.jpg";
//import smepiratemetric from "../../img/sme/smepiratemetric.jpg";

//import smeVdo from "../../vdo/sme-vdo-comp.mp4";
//last serial: 1.5

const ProjectSecondData = [
  //  {
  //    _ID: "sdqme01.2",
  //    projectImg: sme,
  //    projectVdo: smeVdo,
  //    projectHeading:
  //      "Sadaqah Made Easy - Authentic donation projects altogether",
  //    projectSubTitle: (
  //      <>
  //        A web based platform where charitable fundraisers promote their projects
  //        and donors find reliable projects to donate. Charitable organizations
  //        can create request in order to promote their projects. I was the lead
  //        experience designer developed the user flow and designed the whole web
  //        platform's UX with branding elements.
  //      </>
  //    ),
  //    slug: "sme",
  //    based: "Bangladesh",
  //    tag: "Non-profit",
  //    timeline: <span> 11/26/2021 - 02/20/2022</span>,
  //    projectDetails: (
  //      <div>
  //        <div className="container">
  //          <div className="row">
  //            {/*intro */}
  //            <div className="col-md-12 mb-5">
  //              <h3>
  //                A web based platform where charitable fundraisers promote their
  //                projects and donors find reliable projects to donate checking
  //                details. Charitable organizations can create request in order to
  //                promote their projects. I was the lead experience designer
  //                developed the user flow and designed the whole web platform's UX
  //                with branding elements.
  //              </h3>
  //            </div>
  //            <div className="col-md-6 col-12 mt-4">
  //              <h4>
  //                <u>Problem statement</u>
  //              </h4>
  //              <p>
  //                How can authentic charity based donation projects can be
  //                gathered in one platform{" "}
  //                <b className="fw-bold">
  //                  {" "}
  //                  where donors regularly visits to find donation projects and
  //                  organizations joins to promote projects?
  //                </b>
  //              </p>
  //            </div>
  //            <div className="col-md-6 col-12 mt-4">
  //              <h4>
  //                <u>My role</u>
  //              </h4>
  //              <ul>
  //                <li>User research</li>
  //                <li>Wireframe for Home page</li>
  //                <li>UI design</li>
  //                <li>Conducting usability test</li>
  //                <li>Brand identity</li>
  //              </ul>
  //            </div>
  //          </div>
  //          {/*first-design-redesign */}
  //          <div className="row" style={{ marginTop: "8rem" }}>
  //            <div className="col-md-6">
  //              <img src={smefeb} alt="sme-feb-design" className="w-100" />
  //              <p
  //                className="text-center text-secondary mt-3"
  //                style={{ fontSize: "24px" }}
  //              >
  //                First version design
  //              </p>
  //            </div>
  //            <div className="col-md-6">
  //              <img src={smejuly} alt="sme-feb-design" className="w-100" />
  //              <p
  //                className="text-center text-secondary mt-3"
  //                style={{ fontSize: "24px" }}
  //              >
  //                Redesigned version
  //              </p>
  //            </div>
  //          </div>
  //          {/*Design-challenges */}
  //          <div className="row" style={{ marginTop: "5rem" }}>
  //            <div className="col-md-6">
  //              <p className="text-left text-dark" style={{ fontSize: "24px" }}>
  //                Sadaqah Made Easy - SME wants to reconcile all charitable based
  //                fundraising projects in a single platform, that is a single
  //                source of truth of all donation based projects. I, as the lead
  //                designer, wanted to design the experience which will be best fit
  //                to the users of all districts including village people group
  //                throughout Bangladesh.
  //              </p>
  //            </div>
  //            <div className="col-md-6">
  //              {" "}
  //              <p className="text-left text-dark" style={{ fontSize: "24px" }}>
  //                We built our approach around regular cycles of prototyping and
  //                in-person user sessions to make sure we're tackling the proper
  //                problems. We validated every step of the way with feedback from
  //                actual users.
  //              </p>
  //            </div>
  //            <div className="col-md-6" style={{ marginTop: "4rem" }}>
  //              <p
  //                className="text-left text-dark mt-3"
  //                style={{ fontSize: "24px" }}
  //              >
  //                <p>Main design challenges were:</p>
  //                <ul>
  //                  <li className="mb-3">
  //                    {" "}
  //                    Jotting down how village people are used with browsing on
  //                    internet{" "}
  //                  </li>
  //                  <li className="mb-3">
  //                    {" "}
  //                    What target audiences (Non-profit workers, Financially
  //                    victim mostly in village) anticipating from SME{" "}
  //                  </li>
  //                  <li className="mb-3">
  //                    {" "}
  //                    Determining Donor's common behavior in finding charitable
  //                    projects
  //                  </li>
  //                </ul>
  //              </p>
  //            </div>
  //            <div className="col-md-6" style={{ marginTop: "6.5rem" }}>
  //              <p
  //                className="text-left text-dark mt-3"
  //                style={{ fontSize: "24px" }}
  //              >
  //                <ul>
  //                  <li className="mb-3">
  //                    {" "}
  //                    Aligning social workers thought process of promoting
  //                    projects offline with online standard of project promotion
  //                  </li>
  //                  <li className="mb-3">
  //                    {" "}
  //                    Determining in which product or, website, do target
  //                    audiences spend more time?{" "}
  //                  </li>
  //                  <li className="mb-3">
  //                    {" "}
  //                    What brand identity and which colors can create
  //                    confidentiality and loyalty
  //                  </li>
  //                </ul>
  //              </p>
  //            </div>
  //          </div>
  //          {/*product funnel*/}
  //          <div className="row" style={{ marginTop: "8rem" }}>
  //            <div className="col-md-6 align-self-center">
  //              <div className="text-left">
  //                <h4 style={{ fontSize: "36px" }}>
  //                  Understanding users mental model through product funnel
  //                </h4>
  //                <p style={{ fontSize: "20px", fontWeight: "regular" }}>
  //                  Product funnel gives <b className="fw-bold">flexibility</b> in
  //                  realizing mental model. When mass of victims / candidates need
  //                  help in a nation or, in any region, it creates{" "}
  //                  <b className="fw-bold"> awareness </b>by nature. Donors then
  //                  find
  //                  <b className="fw-bold"> interest </b> in donating into any
  //                  reliable project. In the next step, donors observe the
  //                  situation and make decision whether or not, they are going to
  //                  donate. Then the <b className="fw-bold"> purchase</b> thing
  //                  happens which we use here as <b className="fw-bold">donate</b>
  //                  . So, in the final step, the project acquires
  //                  <b className="fw-bold"> loyalty. </b>
  //                </p>
  //              </div>
  //            </div>
  //            <div className="col-md-6">
  //              <img
  //                src={smefunnel}
  //                alt="sme-product-funnel"
  //                className="w-auto"
  //              />
  //              <p
  //                className="text-center text-secondary mt-3"
  //                style={{ fontSize: "14px" }}
  //              >
  //                Product funnel of SME
  //              </p>
  //            </div>
  //          </div>
  //          {/*wireframe one */}
  //          <div className="row" style={{ marginTop: "6rem" }}>
  //            <div className="col-md-6">
  //              <img
  //                src={smewireframeone}
  //                alt="sme-basic-wireframe"
  //                className="w-100 shadow-sm"
  //              />
  //              <p
  //                className="text-center text-secondary mt-3"
  //                style={{ fontSize: "14px" }}
  //              >
  //                The very common layout
  //              </p>
  //            </div>
  //            <div className="col-md-6 align-self-start">
  //              <div className="text-left">
  //                <h4 style={{ fontSize: "36px" }}>
  //                  A common layout / skeleton that people of Bangladesh are very
  //                  used to - Box layout
  //                </h4>
  //                <p style={{ fontSize: "20px", fontWeight: "regular" }}>
  //                  After conducting one-to-one interview with some potential end
  //                  users, A common reflection has been manifested to my designing
  //                  mind while trying to curate the challenges. Native groups{" "}
  //                  <b className="fw-bold"> found familiarity and confidence </b>{" "}
  //                  using a website with box layout based on grid system. For
  //                  example, most of the e-commerce platforms are based on this
  //                  layout and e-commerce is the omni platform where{" "}
  //                  <b className="fw-bold">
  //                    {" "}
  //                    people get influenced by it's content, tone of voice, and
  //                    functionality.
  //                  </b>
  //                </p>
  //              </div>
  //            </div>
  //          </div>
  //          {/*component design rationale*/}
  //          <div className="row" style={{ marginTop: "6rem" }}>
  //            <div className="col-md-12 mb-5">
  //              <h3 style={{ lineHeight: "40px" }}>
  //                The way users check products from e-commerce sites has guided me
  //                to form the main component of SME platform, that is how a
  //                donation project will be displayed.
  //                <b className="fw-bold">
  //                  {" "}
  //                  By levering existing mental model, the project card component
  //                  can deliver superior UX in which the user (donor in this
  //                  context) can focus on their task (reviewing projects) rather
  //                  than learning new models.
  //                </b>
  //              </h3>
  //              <img
  //                src={smecompcarddesign}
  //                alt="sme-common-card"
  //                className="w-100 shadow-sm mt-5"
  //              />
  //              <p
  //                className="text-center text-secondary mt-3"
  //                style={{ fontSize: "14px" }}
  //              >
  //                The rationale behind project card's design
  //              </p>
  //            </div>
  //          </div>
  //          {/*card design*/}
  //          <div className="row" style={{ marginTop: "8rem" }}>
  //            <div className="col-md-5">
  //              <div className="text-left">
  //                <h4 style={{ fontSize: "36px" }}>
  //                  Learning a new product is a big pain, why not reduced it by
  //                  giving the familiar pattern of the prime component to the
  //                  users.
  //                </h4>
  //                <p style={{ fontSize: "20px", fontWeight: "regular" }}>
  //                  This card has been arranged in a logical order, and they’re
  //                  typically displayed in neat grids featuring equal spacing
  //                  between cards. It retains some aspects of the aesthetic of
  //                  flat design, but reintroduce{" "}
  //                  <b className="fw-bold">
  //                    {" "}
  //                    visual depth as a signifier of clickability.
  //                  </b>
  //                  As an influential tool, CTA of the card (button) is filled
  //                  with "deep green" color (WebAIM checked) which is
  //                  psychologically involved with people of Bangladesh.
  //                </p>
  //              </div>
  //            </div>
  //            <div className="col-md-7">
  //              <img
  //                src={smeprojectcard}
  //                alt="sme-project-card"
  //                className="w-100"
  //              />
  //              <p
  //                className="text-center text-secondary mt-3"
  //                style={{ fontSize: "14px" }}
  //              >
  //                Project card visual design
  //              </p>
  //            </div>
  //          </div>
  //          {/*brand identity*/}
  //          <div className="row" style={{ marginTop: "8rem" }}>
  //            <div className="col-md-6">
  //              <div className="text-left">
  //                <h4 style={{ fontSize: "36px" }}>
  //                  Green represents peace, harmony, and tranquillity. It improves
  //                  steadiness and endurance since it is a calming, relaxing
  //                  color.
  //                </h4>
  //                <p style={{ fontSize: "20px", fontWeight: "regular" }}>
  //                  To provide harmomy in use and harmony in look, I used Green as
  //                  primary color and manipulated its variations throughout the
  //                  logo. The word "Sadaqah" means "to donate". Donation makes
  //                  someone live in peace where the donor or the one who receives.
  //                  <br />
  //                  <br />
  //                  When the brand started delivering the same experience with
  //                  every interaction, meaning the same{" "}
  //                  <b className="fw-bold"> tone of voice,</b> the same{" "}
  //                  <b className="fw-bold"> color palette, logo and imagery,</b>{" "}
  //                  the same user experience from the very beginning, people
  //                  started trusting SME . And the brand SME believes trust is the
  //                  most important component of loyalty.
  //                </p>
  //              </div>
  //            </div>
  //            <div className="col-md-6">
  //              <img
  //                src={smebrand}
  //                alt="sme-brand-identity"
  //                className="w-100 shadow-sm"
  //              />
  //              <p
  //                className="text-center text-secondary mt-3"
  //                style={{ fontSize: "14px" }}
  //              >
  //                Brand identity of SME
  //              </p>
  //            </div>
  //          </div>
  //          {/*user testing */}
  //          <div className="row">
  //            <div className="col-md-12 mb-5" style={{ marginTop: "6rem" }}>
  //              <h3>
  //                As I had been tied up doing qualitative and behavioural research
  //                following the non-linear process of designing, another user
  //                interview with potential donors was arranged. My efforts to
  //                achieve consistency was the first and foremost goal. Home page
  //                is the lifeblood of SME platform where consistency should be
  //                maintained. Keeping in mind that, different devices have
  //                different capabilities,{" "}
  //                <b className="fw-bold">
  //                  same interaction style on all different devices{" "}
  //                </b>{" "}
  //                has been applied which strengthen the brand and offered users
  //                the opportunity to take advantage of any knowledge acquired in
  //                previous experience of other sites designed with box layout.
  //              </h3>
  //              <br />
  //              <p className="text-secondary">
  //                Note: Similar mockup photos showed below for easy demonstration{" "}
  //              </p>
  //            </div>
  //            <div className="col-md-6">
  //              <img
  //                src={smeusertestingdesktop}
  //                alt="sme-basic-wireframe"
  //                className="w-100 shadow-sm"
  //              />
  //              <p
  //                className="text-center text-secondary mt-3"
  //                style={{ fontSize: "14px" }}
  //              >
  //                Desktop user testing
  //              </p>
  //            </div>
  //            <div className="col-md-6">
  //              <img
  //                src={smeusertestingmobile}
  //                alt="sme-basic-wireframe"
  //                className="w-100 shadow-sm"
  //              />
  //              <p
  //                className="text-center text-secondary mt-3"
  //                style={{ fontSize: "14px" }}
  //              >
  //                Mobile user testing
  //              </p>
  //            </div>
  //            <div className="text-center p-5">
  //              ❝ In order to establish a habit forming interface, prototype of
  //              Tiktok-like infinite scroll experience of "more projects" was
  //              tested which showed user retention rate may grow. ❞
  //            </div>
  //          </div>
  //          {/*redesign after user testing*/}
  //          <div className="row">
  //            <div className="col-md-12 mb-5" style={{ marginTop: "6rem" }}>
  //              <h3>
  //                After conducting remote usability test using Hotjar, three core
  //                reasons were determined of not increasing registered members /
  //                signups which allowed me to propose for{" "}
  //                <b className="fw-bold"> re-designing the home page,</b> most
  //                importantly, the above-the-fold part
  //                <br />
  //                <br />
  //                In the image below, old design's pitfalls are explained and
  //                showed how re-designed hero section increased clicks on CTA's.
  //              </h3>
  //              <br />
  //            </div>
  //            <div className="col-md-12">
  //              <img
  //                src={smeheatmap}
  //                alt="sme-heatmap"
  //                className="w-100 shadow border"
  //              />
  //              <p
  //                className="text-center text-secondary mt-3"
  //                style={{ fontSize: "14px" }}
  //              >
  //                User testing results and opportunity to redesign
  //              </p>
  //            </div>
  //          </div>
  //          {/*Product life cycle chart*/}
  //          <div className="row" id="product-life-cycle">
  //            {/*<div className="col-md-12 mb-5" style={{ marginTop: "6rem" }}>
  //              <img
  //                src={smeproductmetric}
  //                alt="sme-product-life-cycle"
  //                className="w-100 border"
  //              />
  //            </div>*/}
  //            {/*Product life cycle chart*/}
  //            <div className="row" id="product-life-cycle">
  //              <div className="col-md-12 mb-5" style={{ marginTop: "3rem" }}>
  //                {/*<h2 className="text-center mb-2">
  //                  Measuring maturity - <b>Qualitative approach</b>
  //                </h2>
  //                <h5 className="text-center">
  //                  Factors considering as leading indicators of whether the
  //                  product is advancing in the right direction.
  //                </h5>
  //                <div className=" mt-5">
  //                  <img
  //                    src={smelifecycle}
  //                    alt="sme-product-life-cycle"
  //                    className="w-100 border"
  //                  />
  //                  <p
  //                    className="text-center text-secondary mt-3"
  //                    style={{ fontSize: "14px" }}
  //                  >
  //                    Product life cycle of SME
  //                  </p>
  //                </div>*/}
  //                <h2
  //                  style={{
  //                    marginTop: "8rem",
  //                    marginBottom: "3rem",
  //                    fontWeight: "bold",
  //                  }}
  //                >
  //                  {" "}
  //                  Progress towards PMF
  //                </h2>
  //                <p>Finding out PMF insight of SME (Orgs are our customers):</p>
  //                <p className="text-success mt-2 mb-5">
  //                  Framework used: 5-question quiz, Points: 11
  //                </p>
  //                <p className="mb-5">
  //                  1. Diversity of customers:{" "}
  //                  <b className="fw-bold">
  //                    {" "}
  //                    How many orgs do you have that you DID NOT KNOW before
  //                    starting the company? (3)
  //                    <br />
  //                    <br />
  //                    Ans: We got 3 orgs at the Introduction phase. Now, we have
  //                    18 enlisted orgs with their profile page in SME web. So the
  //                    increase is 6x.They are promoting us and we are promoting
  //                    them.
  //                  </b>{" "}
  //                  <br />
  //                  <br />
  //                  <img
  //                    src={smeorgincreaserate}
  //                    alt="sme-org-increase-rate mt-5"
  //                    className="w-100 border"
  //                  />
  //                  <p
  //                    className="text-center text-secondary mt-3"
  //                    style={{ fontSize: "14px" }}
  //                  >
  //                    Orgs increase rate
  //                  </p>
  //                </p>
  //                <p className="mb-5">
  //                  2. Engagement:{" "}
  //                  <b className="fw-bold">
  //                    How much pain would they experience if the SME platform is
  //                    closed? (3)
  //                    <br />
  //                    <br />
  //                    Ans: Almost 8 orgs are fully or partially depended on their
  //                    SME profile link to promote their org online. There are
  //                    chances they would face pitfall if SME get closed. And we
  //                    have more than 120+ of registered members who visits the
  //                    site at least 2 times a week. Sometimes there is high
  //                    engagement and sometimes it has no update in the platform.
  //                  </b>
  //                </p>
  //                <p className="mb-5">
  //                  3. Churn:{" "}
  //                  <b className="fw-bold">
  //                    What is the rate at which you are losing orgs? (1)
  //                    <br />
  //                    <br />
  //                    Ans: We hadn’t face loosing any orgs yet but there has been
  //                    several numbers of rejection from orgs while asking for
  //                    enlisting. We faced more than 5 rejections within 3 months
  //                    after launch. Though this hasn’t created any negative impact
  //                    on our progress. We have to identify why we got rejections.
  //                  </b>
  //                </p>
  //                <p className="mb-5">
  //                  4. Commitment level:{" "}
  //                  <b className="fw-bold">
  //                    Do you have orgs on pilot programs / feasibility study? (1)
  //                    <br />
  //                    <br />
  //                    Ans: We have promoted two self-initiated project supervised
  //                    by one of our enlisted referrer in Sylhet. One of the
  //                    candidates of the first project got 2k as donation from
  //                    donors. The referrer gave commitment to us for further
  //                    investigation. Fortunately, a donor offered us a place to
  //                    give us for running field projects.
  //                  </b>
  //                </p>
  //                <p className="mb-5">
  //                  5. Word of mouth and virality:{" "}
  //                  <b className="fw-bold">
  //                    {" "}
  //                    How are you acquiring new customers? (3)
  //                    <br />
  //                    <br />
  //                    Ans: Most of our enlisted projects are promoting via our web
  //                    platform and social channel online. People are searching for
  //                    donation projects in both of the medium. As
  //                    candidates/victims are getting support via SME, they are
  //                    referring our enlisted orgs to other candidates.
  //                  </b>
  //                </p>
  //              </div>
  //              <div className="col-md-12 mb-5" style={{ marginTop: "6rem" }}>
  //                <h2 className="text-center mb-2">
  //                  Measuring maturity - <b>Quantitative approach</b>
  //                </h2>
  //                <h5 className="text-center text-primary mt-4 mb-5">
  //                  KPI's we are leveraging (using Pirate Metric framework) to
  //                  determine quantitative rate for PMF indication:
  //                  <br />
  //                  <br />
  //                  <b className="fw-bold">
  //                    Acquisition, Retention, Revenue, Activation, Referral
  //                  </b>
  //                </h5>
  //                <img
  //                  src={smepiratemetric}
  //                  alt="sme-product-pirate-metric"
  //                  className="w-100 border"
  //                />
  //                <p
  //                  className="text-center text-secondary mt-3"
  //                  style={{ fontSize: "14px" }}
  //                >
  //                  Pirate metric - Towards PMF
  //                </p>
  //              </div>
  //            </div>
  //            <div className="col-md-12" style={{ marginTop: "6rem" }}>
  //              <img
  //                src={smelifecycle}
  //                alt="sme-product-life-cycle"
  //                className="w-100 border"
  //              />
  //              <p
  //                className="text-center text-secondary mt-3"
  //                style={{ fontSize: "14px" }}
  //              >
  //                Product life cycle of SME
  //              </p>
  //            </div>
  //          </div>
  //        </div>
  //        {/*other screens */}
  //        <div className="container-fluid" style={{ marginTop: "8rem" }}>
  //          <div className="row">
  //            <div className="col-md-12">
  //              <h4 style={{ fontSize: "36px" }}>Other screens</h4>
  //              <p
  //                style={{
  //                  fontSize: "20px",
  //                  fontWeight: "normal",
  //                  marginBottom: "1rem",
  //                }}
  //              >
  //                SME platform also has organization featured section, blogs,
  //                career opportunity section, organization profile page which has
  //                been designed consistently. Responsiveness may not be completely
  //                over now by developer's team, but my task was accomplished.
  //              </p>
  //              <div className="d-flex align-self-center justify-content-center">
  //                <div>
  //                  <img
  //                    src={smeotherscreensone}
  //                    alt="sme-other-screens"
  //                    className=""
  //                    style={{ width: "1400px" }}
  //                  />
  //                  <p
  //                    className="text-center text-secondary"
  //                    style={{ fontSize: "14px", marginTop: "-5rem" }}
  //                  >
  //                    Cluster view of Home page
  //                  </p>
  //                </div>
  //              </div>
  //            </div>
  //            <div className="col-md-12" style={{ marginTop: "1rem" }}>
  //              <img
  //                src={smeotherscreenstwo}
  //                alt="sme-other-screens"
  //                className="w-100"
  //              />
  //              <p
  //                className="text-center text-secondary"
  //                style={{ fontSize: "14px", marginTop: "-5rem" }}
  //              >
  //                Project details page, Organization list, Orgnaziation profile,
  //                Request form
  //              </p>
  //            </div>
  //            <div className="col-md-12" style={{ marginTop: "8rem" }}>
  //              <img
  //                src={smeresponsive}
  //                alt="sme-responsive-screens"
  //                className="w-100"
  //              />
  //              <p
  //                className="text-center text-secondary"
  //                style={{ fontSize: "14px", marginTop: "5rem" }}
  //              >
  //                Mobile responsive interfaces
  //              </p>
  //            </div>
  //          </div>
  //          <div className="col-md-12" style={{ marginTop: "8rem" }}>
  //            <h5 style={{ fontSize: "28px", textAlign: "center" }}>
  //              As I had major contribution in building the platform, why not
  //              sharing some real reviews!
  //            </h5>
  //            <img src={smereviews} alt="sme-reviews" className="w-100" />
  //            <p
  //              className="text-center text-secondary mt-4"
  //              style={{ fontSize: "14px" }}
  //            >
  //              Reviews submitted on Trustpilot.com
  //            </p>
  //          </div>
  //          {/*The impact and lesson learnt */}
  //          <div
  //            className="d-flex justify-content-center"
  //            style={{ marginTop: "12rem" }}
  //          >
  //            <div className="flex-div border rounded bg-light shadow-sm p-3 me-3">
  //              <h2 className="text-center mb-4">
  //                <u>The impact</u>
  //              </h2>
  //              <ul
  //                style={{
  //                  listStyleType: "none",
  //                  textAlign: "left",
  //                  fontSize: "28px",
  //                  lineHeight: "45px",
  //                }}
  //              >
  //                <li>
  //                  <i className="fa fa-check-circle text-secondary me-3"></i>
  //                  Launched first version on February, 2022{" "}
  //                </li>
  //                <li>
  //                  <i className="fa fa-check-circle text-secondary me-3"></i>
  //                  Apparently operated branding campaigns{" "}
  //                </li>
  //                <li>
  //                  <i className="fa fa-check-circle text-secondary me-3"></i>
  //                  Increased exposure rate to 10% to 70% within 2 months{" "}
  //                </li>
  //                <li>
  //                  <i className="fa fa-check-circle text-secondary me-3"></i>
  //                  Built the product from seed stage{" "}
  //                </li>
  //                <li>
  //                  <i className="fa fa-check-circle text-secondary me-3"></i>
  //                  Redesigned on July, 2022 based on usability result{" "}
  //                </li>
  //              </ul>
  //            </div>
  //            <div className="flex-div border rounded shadow-sm p-3">
  //              <h2 className="text-center mb-4">
  //                <u>Lesson learnt</u>
  //              </h2>
  //              <ul
  //                style={{
  //                  listStyleType: "none",
  //                  textAlign: "left",
  //                  fontSize: "28px",
  //                  lineHeight: "45px",
  //                }}
  //              >
  //                <li>
  //                  <i className="fa fa-thumbs-up text-secondary me-3"></i>
  //                  Above-the-fold doesn't always work in leveling up conversion
  //                  rate, scroll down does.
  //                </li>
  //                <li>
  //                  <i className="fa fa-thumbs-up text-secondary me-3"></i>
  //                  One of the favorite tasks of every types of users is -
  //                  "copy-paste".
  //                </li>
  //                <li>
  //                  <i className="fa fa-thumbs-up text-secondary me-3"></i>
  //                  In time of national disaster, bad UX matters.
  //                </li>
  //              </ul>
  //            </div>
  //          </div>
  //        </div>
  //        <p className="text-center" style={{ marginTop: "5rem" }}>
  //          - A big thank you for the time you spent reading this case study -
  //        </p>
  //      </div>
  //    ),
  //  },
];

export function getProjectSecondData() {
  return ProjectSecondData;
}
