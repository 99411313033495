import uniqueformrelationship from "../../../img/anlytic/uniqueformrelationship.png";
function Right() {
  return (
    <div className="col-12 col-md-4 attention-two-middle">
      <a
        href="https://drive.google.com/file/d/1zH79Bbfue4ssI_tvA7ecvnjkFW7kXgPk/view?usp=sharing"
        target="__blank"
      >
        <div className="card mt-5 p-0">
          <div className="card-body">
            <h4 className="card-title text-end" style={{ color: "#cccccc" }}>
              Design fosters sound collab &nbsp;
              <i className="fa fa-handshake-o" aria-hidden="true"></i>
            </h4>
            <h4 className="card-text fw-light text-end text-light">
              Team communication made simple
            </h4>

            <div className="card-bottom-img">
              <img
                src={uniqueformrelationship}
                alt="unique-form-relationship"
              />
            </div>
            {/*<a
              className="btn btn-sm btnCTA ramadan__project-btn"
              href="https://anlytic.com"
              target="__blank"
            >
              Use the app!
            </a>*/}
          </div>
        </div>
      </a>
    </div>
  );
}
export default Right;
