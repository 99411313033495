import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import StateProvider from "./Context/StateProvider";
import Reducer, { initialState } from "./Context/Reducer";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "font-awesome/css/font-awesome.min.css";
import { ParallaxProvider } from "react-scroll-parallax";
import "animate.css";
import "./sass/style.css";

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} Reducer={Reducer}>
      <ParallaxProvider>
        <App />
      </ParallaxProvider>
    </StateProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
