import bdotmap from "../../vdo/bdotmap.mp4";
import bdotcoverVdo from "../../vdo/bdotcoverVdo.mp4";
import bdotiteration1 from "../../vdo/bdotiteration1.mp4";
import bdotiteration2 from "../../vdo/bdotiteration2.mp4";

import bdotintroimg from "../../img/bikedot/bdotintroimg.jpeg";
import bdotcover from "../../img/bikedot/bdotcover.jpg";
import bdottimeline from "../../img/bikedot/bdottimeline.jpg";
import bdotleandesign from "../../img/bikedot/bdotleandesign.jpg";
import bdotassumptionshypo from "../../img/bikedot/bdotassumptionshypo.jpg";
import bdotpersona from "../../img/bikedot/bdotpersona.jpg";
import bdotfielduser from "../../img/bikedot/bdotfielduser.jpg";
import bdotrequirements from "../../img/bikedot/bdotrequirements.jpeg";
import bdotsketch from "../../img/bikedot/bdotsketch.jpeg";
import bdotinfoarc from "../../img/bikedot/bdotinfoarc.jpeg";
import bdotuserflow from "../../img/bikedot/bdotuserflow.jpeg";
import bdotuidesign1 from "../../img/bikedot/bdotuidesign1.jpeg";
import bdotuidesign2 from "../../img/bikedot/bdotuidesign2.jpeg";
import bdotuidesign3 from "../../img/bikedot/bdotuidesign3.jpeg";
import bdotuidesign4 from "../../img/bikedot/bdotuidesign4.jpeg";
import bdotutest1 from "../../img/bikedot/bdotutest1.jpeg";
import bdotutest2 from "../../img/bikedot/bdotutest2.jpeg";
import bdotutest3 from "../../img/bikedot/bdotutest3.jpeg";
import bdotqr from "../../img/bikedot/bdotqr.png";

//last serial: 1.5

const ProjectFourthData = [
  {
    _ID: "sdqme01.4",
    projectImg: bdotcover,
    projectVdo: bdotcoverVdo,
    projectHeading: "BikeDot - Find nearest eBike charging point",
    projectSubTitle: (
      <>
        An app helps drivers to locate & connect charger to their electric
        bikes, two wheelers, scooters ideally installed in restaurants, cafes
        and malls across the city. Users can choose whether or not to plug
        charger with a certain point based on specifications like connector
        type, location, payment and reviews. My role was to design the
        experience of the very first version of this app.
      </>
    ),
    slug: "bikedot",
    based: "Bangladesh",
    tag: "Green tech, Map",
    timeline: <span> 02/2021 - 03/2021</span>,
    projectDetails: (
      <div className="row">
        {/*intro */}
        <div className="col-md-12 mb-5">
          <h3>
            An app helps drivers to locate & connect nearest charger to their
            electric bikes, two wheelers, scooters ideally installed in
            restaurants, cafes and malls across the city. Users can choose
            whether or not to plug charger with a certain point based on
            specifications like connector type, location, payment and reviews.
            At Bikedot, Users can manage transactions for their charging session
            also through this app - which has not yet been added in the current
            version.
          </h3>
          <p
            className="text-secondary text-center fw-light mt-5 ps-2 pe-2"
            style={{ fontSize: "22px" }}
          >
            <i>
              To comply with my confidentiality agreement I have omitted and
              appropriated confidential information. These documentation and
              designs are the reinterpretation of the original.
            </i>
          </p>
        </div>
        <div className="col-md-6 col-12 mt-4">
          <h4>
            <u>Problem statement</u>
          </h4>
          <p>
            People of Dhaka wants to transform their city into a electric
            biking, a city where decarbonisation movement is happening, with the
            advent of BikeDot charging stations. Users of eBike, e Four
            wheelers, eScooters are increasing slowly but surely creating
            impacts. BikeDot customers are complaining for not having any app to
            find nearest charging point which causing decline in sales growth.
            Now, the time has come{" "}
            <b className="fw-bold">
              to inaugurate an app to find nearby eBike charging points easily
              and share thoughts with friends & family.
            </b>{" "}
          </p>
        </div>
        <div className="col-md-6 col-12 mt-4">
          <h4>
            <u>My role</u>
          </h4>
          <ul>
            <li>User research</li>
            <li>Wireframes</li>
            <li>Hi-fi Prototyping</li>
            <li>Conduct moderated usability testing </li>
            <li>UI design</li>
          </ul>
        </div>

        {/*intro screens */}
        <div className="col-md-12">
          <img src={bdotintroimg} alt="bdot-intro-img" className="w-100" />
          <p
            className="text-center text-secondary mt-3"
            style={{ fontSize: "14px" }}
          >
            BikeDot app screens
          </p>
        </div>

        {/*The challenge */}
        <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
          <h3 className="mb-4 fw-bold">The challenge</h3>
          <p style={{ lineHeight: "35px", fontSize: "22px" }}>
            The client came to me with two main goals: (i) to design their app's
            usability and functionality which should be well-aligned with
            Socioeconomic status quo; and (ii) by designing, encourage the Dhaka
            eBikers community to lead the movement on forth.
          </p>
        </div>
        <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
          <h3 className="mb-4 fw-bold">Timeline</h3>
          <p style={{ lineHeight: "35px", fontSize: "22px" }}>
            I had 4 weeks to submit the final prototype. The combination of a
            fixed deadline, prototype submission and usability testing made the
            work over loaded. Yet, this added the challenge more proactiveness.
          </p>
        </div>
        <div className="col-md-4 mb-5" style={{ marginTop: "5rem" }}>
          <h3 className="mb-4 fw-bold">With stakeholders</h3>
          <p style={{ lineHeight: "35px", fontSize: "22px" }}>
            We better understood the overall business difficulties, thanks to
            our key stakeholders. Together, we were able to identify risks,
            agree on goals, and create a clear vision for the app.
          </p>
        </div>

        {/*design process */}
        <div className="col-md-12" style={{ marginTop: "2rem" }}>
          <p>
            {" "}
            <b>The process</b>
          </p>
          <h3 style={{ lineHeight: "35px" }}>
            We used the <b className="fw-bold">Lean UX methodology </b>that
            prioritized declaring hypotheses, frequent sketching, prototyping,
            user feedback, and visual design. This inspired a ton of effective
            ideas, and a strong sense of design ownership across the timeline
          </h3>
          <img
            src={bdotleandesign}
            alt="bdot-timeline"
            className="w-100 mt-5"
          />
          <p
            className="text-center text-secondary"
            style={{ fontSize: "14px", marginTop: "3rem" }}
          >
            Lean UX in a frame
          </p>
        </div>

        {/*Timeline demonstration*/}
        <div className="col-md-12 mb-5" style={{ marginTop: "1rem" }}>
          <img src={bdottimeline} alt="bdot-timeline" className="w-100 mt-4" />
          <p
            className="text-center text-secondary"
            style={{ fontSize: "14px", marginTop: "-3rem" }}
          >
            Timeline showing 4 weeks of progression
          </p>
        </div>

        {/*sketch - user research - who are the users*/}
        <div className="col-md-12 mb-5" style={{ marginTop: "5rem" }}>
          <p>
            {" "}
            <b>Declaring assumptions</b>
          </p>
          <h3 style={{ lineHeight: "45px" }}>
            First Things First - together with key stakeholders and subject
            matter experts, we stated the{" "}
            <a href="#top" className="text-primary">
              problem
            </a>{" "}
            repeatedly which allowed us to brainstorm our ideas by creating a
            shared understanding of what we all assume and a broad view of
            possible solutions. In this phase, we generated answers to certain
            questions that form our{" "}
            <b className="fw-bold">Axiological assumptions</b> leading us
            present what is important and valuable (Prioritization). Declared
            assumptions played an important role to create a launching point for
            the product itself.
          </h3>
          <img
            src={bdotassumptionshypo}
            alt="bdot-timeline"
            className="w-100 mt-5"
          />
          <p
            className="text-center text-secondary"
            style={{ fontSize: "14px", marginTop: "3rem" }}
          >
            Creating hypotheses
          </p>
        </div>

        {/*field user research*/}
        <div className="row" style={{ marginTop: "5rem" }}>
          <div className="col-md-6 align-self-center">
            <p>
              {" "}
              <b>Who is the user?</b>
            </p>
            <div className="text-left">
              <h4 style={{ fontSize: "28px", lineHeight: "38px" }}>
                Being in the user’s context and location, asking the right
                questions and looking in the right places for the right data was
                challenging and fun
              </h4>
              <p style={{ fontSize: "20px", fontWeight: "regular" }}>
                Before proposing something new (design), I went out to{" "}
                <b className="fw-bold"> gather task information</b> to find out
                how people do things everyday with eBikes and why they do them
                in particular ways. To help prevent big mistakes when creating
                design and prototype in this "Lean UX process", early design
                research like this was time-saving.
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img src={bdotfielduser} alt="bdot-field-user" className="w-100" />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              To gain some real experience walking with eBike people
            </p>
          </div>
        </div>

        {/*user persona */}
        <div className="col-md-12 mb-5" style={{ marginTop: "5rem" }}>
          <h3 style={{ lineHeight: "45px" }}>
            Prioritizing who we will be focusing on assisting in the first
            version <b className="fw-bold"> based on the hypotheses created </b>
            became possible after entitling persona categories and coordinating
            this with our ongoing process.
          </h3>
          <img src={bdotpersona} alt="bdot-timeline" className="w-100 mt-5" />
          <p
            className="text-center text-secondary"
            style={{ fontSize: "14px", marginTop: "3rem" }}
          >
            User persona with demographics
          </p>

          <h4
            className="text-center"
            style={{ lineHeight: "45px", marginTop: "5rem" }}
          >
            ❝We used personas constantly throughout the project to guide design
            decisions, priorities, and create empathy amongst the client and
            stakeholders.❞
          </h4>
        </div>

        {/*Crafting requirements */}
        <div className="col-md-12 mb-5" style={{ marginTop: "5rem" }}>
          <p>
            {" "}
            <b>Crafting requirements</b>
          </p>
          <h3 style={{ lineHeight: "45px" }}>
            Walking with end users on field research phase helped me to develop
            a clear vision of the core expectations. I, consulting with subject
            matter experts,{" "}
            <b className="fw-bold">
              {" "}
              developed a set of experience principles which were used
              frequently in design-check to drive app's aesthetic and look 'n
              feel.{" "}
            </b>
          </h3>
          <img
            src={bdotrequirements}
            alt="bdot-requirements"
            className="w-100 mt-5"
          />
          <p
            className="text-center text-secondary"
            style={{ fontSize: "14px", marginTop: "3rem" }}
          >
            Gathered requirements from persona and user interview
          </p>
        </div>

        {/*Road to prototype - paper sketch */}
        <div className="col-md-12 mb-5" style={{ marginTop: "5rem" }}>
          <p>
            {" "}
            <b>Road to prototype - Paper works</b>
          </p>
          <h3 style={{ lineHeight: "45px" }}>
            Following the Lean UX approach, I built the most basic sketch
            version of the concept as possible to communicate design with our
            core requirements while allowing the design to iterate multiple
            ideas before settling on one. As per client requirement, without too
            much hassle, the building of first version on its way...
          </h3>
          <img src={bdotsketch} alt="bdot-sketch" className="w-100 mt-5" />
          <p
            className="text-center text-secondary"
            style={{ fontSize: "14px", marginTop: "3rem" }}
          >
            Paper sketch of the basic user flow
          </p>
        </div>

        {/*Road to prototype - information architecture*/}
        <div className="col-md-12 mb-5" style={{ marginTop: "5rem" }}>
          <p>
            {" "}
            <b>Road to prototype - Architecture of the app</b>
          </p>
          <h3 style={{ lineHeight: "45px" }}>
            After clients quick affirmation on the content and functionality of
            previously showed paper sketch, I started structuring the app,
            information architecture on its way...
          </h3>
          <img src={bdotinfoarc} alt="bdot-infoarch" className="w-100 mt-5" />
          <p
            className="text-center text-secondary"
            style={{ fontSize: "14px", marginTop: "3rem" }}
          >
            Information architecture
          </p>
        </div>

        {/*Road to prototype - primary user flow*/}
        <div className="col-md-12 mb-5" style={{ marginTop: "5rem" }}>
          <p>
            {" "}
            <b>Road to prototype - User flow</b>
          </p>
          <h3 style={{ lineHeight: "45px" }}>
            Composing key user journeys for each user's persona, I developed the
            very basic userflow which our users would follow through the app to
            find a eBike charging point.
          </h3>
          <img src={bdotuserflow} alt="bdot-infoarch" className="w-100 mt-5" />
          <p
            className="text-center text-secondary"
            style={{ fontSize: "14px", marginTop: "3rem" }}
          >
            Basic user flow
          </p>
        </div>

        {/*Road to prototype - intro interaction*/}

        <div
          className="d-flex justify-content-center flex-column align-self-center align-items-center"
          style={{ marginTop: "3rem", marginBottom: "3rem" }}
        >
          <h3
            style={{
              lineHeight: "45px",
              textAlign: "center",
              marginBottom: "5rem",
            }}
          >
            Quickly prototyped the moment when a user interacts with "Search
            nearest charging point" flow . In this phase, I used the perfect
            combination tool Figma. A bottom sheet rises up to show charging
            point details above the fold / within the map to decrease{" "}
            <b className="fw-bold">cognitive load.</b>
          </h3>
          <video
            autoPlay
            loop
            muted
            width="300"
            height="600"
            style={{
              clipPath: "inset(2px 2px)",
            }}
          >
            <source src={bdotmap} type="video/mp4"></source>
          </video>
          <p
            className="text-center text-secondary"
            style={{ fontSize: "14px", marginTop: "3rem" }}
          >
            User searching nearest charging point using map
          </p>
        </div>

        {/*detailed design-1*/}
        <div className="row" style={{ marginTop: "5rem" }}>
          <div className="col-md-12 text-center">
            <p>
              {" "}
              <b>Detailed design</b>
            </p>
            <h4 style={{ lineHeight: "45px" }}>
              LET'S EXPERIENCE THE VISUALS OF FIRST VERSION
            </h4>
            <h3 style={{ lineHeight: "45px", marginBottom: "7rem" }}>
              To support people on-the-go, map is primarily designed so that
              they can depend on their prior experience with Google Maps and
              Apple maps, norms to assist people with learning how to use the
              app - Discoverability.
            </h3>
            {/*bdotuidesign1*/}
            <img src={bdotuidesign1} alt="bdot-field-user" className="w-100" />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Left: Authentication || Right: Home
            </p>

            {/*bdotuidesign2*/}
            <h3
              style={{
                lineHeight: "45px",
                marginTop: "12rem",
                marginBottom: "7rem",
              }}
            >
              Keeping two types of patterns in navigating charging points, it
              steered less essential elements away from the main screen and
              manoeuvre it into the main goal. Cards and contents and CTA of the
              cards, are{" "}
              <b className="fw-bold">
                placed within users "thumb zone" so that users can access
                easy-to-reach area of the screen when they are riding !
              </b>
            </h3>
            <img src={bdotuidesign2} alt="bdot-field-user" className="w-100" />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Left: Bottom sheet containing details || Right: List view of
              nearest charging points
            </p>

            {/*bdotuidesign3*/}
            <h3
              style={{
                lineHeight: "45px",
                marginTop: "12rem",
                marginBottom: "7rem",
              }}
            >
              The Charging Point Details screen let users decide based on
              specifications like connector type, location, payment and reviews
              are going to have. It gives users insight into how it will benefit
              them once they use it.{" "}
              <b className="fw-bold">
                Real image of connectors has been used as users seem more
                realistic and trustworthy and are easier to identify with their
                eBike's connector. This details page is designed following
                established interface patterns (Industry Standard) and mechanics
                to manipulate familiarity.
              </b>{" "}
              As the first version only allows Manual Payment system, the other
              popular payment systems is kept disabled (unavailable) but still
              presents that those two systems will be available in the upcoming
              version.
            </h3>
            <img
              src={bdotuidesign3}
              alt="bdot-charging-details"
              className="w-100"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Left: Charging point details upper portion || Right: Charging
              point details lower portion shows CTA
            </p>

            {/*bdotuidesign4*/}
            <h3
              style={{
                lineHeight: "45px",
                marginTop: "12rem",
                marginBottom: "7rem",
              }}
            >
              In this phase, users focus should be on that single content that
              they care about most, the timer which enhances{" "}
              <b className="fw-bold"> Predictability.</b> By leveraging the
              foundation principle,{" "}
              <b className="fw-bold">
                {" "}
                "People don't want to buy a quarter-inch drill. They want a
                quarter inch hole!",{" "}
              </b>{" "}
              the design lets user achieve the result making the interfaces
              uncluttered and creating a clear hierarchy.{" "}
            </h3>
            <img
              src={bdotuidesign4}
              alt="bdot-charging-loader-timer"
              className="w-100"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Charging start loader and timer
            </p>
          </div>
        </div>

        {/*Usability Testing */}
        <div className="row" style={{ marginTop: "8rem" }}>
          <div className="col-md-6">
            <p>
              {" "}
              <b>Usability Testing</b>
            </p>
            <h3 style={{ lineHeight: "45px", fontSize: "28px" }}>
              To gain valuable feedback from the perspective of the end-user, I
              performed <b className="fw-bold"> Guerilla testing</b>. As the
              product itself is an unparalleled and there were short of
              available resources, I selected this simplest form of usability
              testing. Going into the hub of eBikers in the Dhaka city, I showed
              the tangible design (Prototype) to some random eBikers
              prioritizing all possible scenarios of interactions (core user
              flow of searching and selecting charging point to charge)
            </h3>
          </div>
          <div className="col-md-6">
            <img
              src={bdotutest1}
              alt="bdot-usability-testing"
              className="w-100 mt-5"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              A random eBiker expected some relevant text about what-to-do when
              onboards the main screen
            </p>
          </div>
        </div>

        {/*iteration-1*/}
        <div className="row" style={{ marginTop: "8rem" }}>
          <div className="col-md-12">
            <h3 style={{ lineHeight: "45px" }}>
              In the usability testing session, participants shared that they
              were like "Now what?" just after onboarding (reaching into the map
              screen which is basically the Home screen). Our hypotheses was
              that users can find familiarity with Google/Apple map, but somehow
              users expected something to learn about the next step at the
              startup stage.
              <br />
              <br />I aided this usability issue by adding a Pop-up over the map
              which remove a lot of the guesswork from the UI and make it
              instantly usable for the users.
            </h3>

            <img
              src={bdotutest2}
              alt="bdot-usability-testing-2"
              className="w-100 mt-5"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Iterated Home screen after Guerilla test
            </p>
          </div>
        </div>

        {/*iteration-2*/}
        <div className="row" style={{ marginTop: "8rem" }}>
          <div className="col-md-12">
            <h3 style={{ lineHeight: "45px" }}>
              Three of the participants wanted to view charging point's image
              separately to co-relate nearby places which helps to find places
              more easily
            </h3>

            <img
              src={bdotutest3}
              alt="bdot-usability-testing-2"
              className="w-100 mt-5"
            />
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Iterated upper component of the details page by adding 'Tap to
              view' button which incorporates a image modal
            </p>
          </div>
        </div>

        {/*iterations in vdo*/}

        <div
          className="d-flex justify-content-around align-items-center"
          style={{ marginTop: "8rem", marginBottom: "8rem" }}
        >
          <div>
            <video
              autoPlay
              loop
              muted
              width="300"
              height="600"
              style={{
                clipPath: "inset(2px 2px)",
              }}
            >
              <source src={bdotiteration1} type="video/mp4"></source>
            </video>
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Pop-up over map educates users what to do next
            </p>
          </div>

          <div>
            <video
              autoPlay
              loop
              muted
              width="300"
              height="600"
              style={{
                clipPath: "inset(2px 2px)",
              }}
            >
              <source src={bdotiteration2} type="video/mp4"></source>
            </video>
            <p
              className="text-center text-secondary"
              style={{ fontSize: "14px", marginTop: "3rem" }}
            >
              Modal containing image makes easier to identify
            </p>
          </div>
        </div>

        <div
          className="row border rounded p-5 shadow-sm"
          style={{ marginTop: "2rem" }}
        >
          <div className="col-md-12">
            <h3 style={{ lineHeight: "45px" }}>
              There are two more features, one of which is the "Community" is
              currently linked up with a facebook group link, as per client's
              requirement. Their plan is to keep the eBiker's community engaged
              through facebook. And the "Bikedot Store" has not released yet in
              the first version. "BikeDot Store" will be an online store based
              feature enabling users to purchase eBike from the store. The
              current version is focusing on collecting user data and feedback
              to give more personalized experience in the future release.
            </h3>
          </div>
        </div>

        {/*proto-qr*/}
        <div
          style={{
            marginTop: "10rem",
          }}
          className="row"
        >
          <div className="col-md-12 col-12 mb-4">
            <h4
              style={{
                fontSize: "28px",
                //lineHeight: "50px",
                textAlign: "left",
              }}
            >
              Scan this QR code to view prototype in Figma
            </h4>
          </div>
          <div className="col-md-6 col-12 text-left">
            <img src={bdotqr} alt="finshare-qr" className="w-50" />
            <p className="fw-bold mt-3" style={{ fontSize: "16px" }}>
              Scan Me
            </p>
          </div>
        </div>

        {/*The impact and lesson learnt */}
        <div
          className="d-flex justify-content-center p-3"
          style={{ marginTop: "5rem" }}
        >
          <div className="flex-div border rounded bg-light shadow-sm p-3 me-3">
            <h2 className="text-center mb-4">
              <u>The impact</u>
            </h2>
            <ul
              style={{
                listStyleType: "none",
                textAlign: "left",
                fontSize: "28px",
                lineHeight: "45px",
              }}
            >
              {/*<li>
                <i className="fa fa-check-circle text-secondary me-3"></i>
                Revenue growth of 0.3% has been achieved in first 5 months of
                app's launch. Company was expecting the targeted rate would be
                achieved within next 5 months (estimated)
              </li>*/}
              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>
                Restaurant & cafe owners started connecting with BikeDot through
                the app to install charging point of eBikes to create a funnel
                of ROI for their business
              </li>

              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>
                Community engagement is making more impact aligning with the
                story behind the movement
              </li>
              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i> User
                feedbacks are creating opportunities to polish the app for the
                final version.
              </li>
            </ul>
          </div>

          <div className="flex-div border rounded shadow-sm p-3">
            <h2 className="text-center mb-4">
              <u>Lesson learnt</u>
            </h2>
            <ul
              style={{
                listStyleType: "none",
                textAlign: "left",
                fontSize: "28px",
                lineHeight: "45px",
              }}
            >
              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                Two wheelers are fast thinkers, usability test should be
                conducted with more arrangement
              </li>

              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                Designing is direclty related with social movements
              </li>
              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                Community tool is the key for rapid engagements
              </li>
            </ul>
          </div>
        </div>

        <p className="text-center" style={{ marginTop: "5rem" }}>
          - A big thank you for the time you spent reading this case study -
        </p>
      </div>
    ),
  },
];

export function getProjectFourthData() {
  return ProjectFourthData;
}
