//import ribainislam from "../../img/ribainislam.jpeg";

//last serial: 4

export const blogs = [
  {
    _ID: "smeblog002.1",
    name: "Managing an income source for a Muslim",
    img: "manageincome",
    slug: "managing-income-source",
    author: "Rahat Imroz Ahmed",
    details: (
      <div>
        <div>
          <h4 className="fw-bold">
            The best act of Sadaqah Jariyah that will give you an immeasurable
            reward
          </h4>
          <p>
            We only got this Dunya (worldly life) to please Allah and earn his
            grace. Once we die, the chance of worshiping him goes away. Is there
            a way to get the continuous rewards of Allah even after a Muslim
            leaves this world?
          </p>
          <p>
            Alhamdulillah, Allah has also given us the chance to earn his
            rewards after death through Sadaqah Jariyah.
          </p>
          <p>
            The Prophet Muhammad(pbuh) said: “When a human being dies, all one’s
            deeds cease, save three: a Sadaqah Jariyah, [religious] knowledge
            [one leaves behind] from which others benefit, and a righteous child
            who prays for one” (Muslim).
          </p>
          <p>
            Managing an income source for a Muslim as a Sadaqah Jariyah is one
            of the best ways to get Allah’s blessing in the world and the
            hereafter.
          </p>
          <p>
            Before explaining why this act of charity is the best way to please
            Allah, let’s understand the difference between Sadaqah and Sadaqah
            Jariah.
          </p>
          <h4 className="fw-bold">Sadaqah and Sadaqah Jariyah</h4>
          <p>
            General Sadaqah is a one-time donation. For example, giving someone
            food to alleviate his hunger could be a way of normal sadaqah. Here
            the recipient gets the help for once.
          </p>
          <p>
            Sadaqah Jariyah is a special type of charity that is long-term and
            ongoing. The recipient of sadaqah Jariyah will get continuous help
            from this special kind of charity. In hadith, we find an example of
            Sadaqah Jariyah.
          </p>
          <p>
            Rasulullah (SAW) said: "If a Muslim plants a tree or sows seeds, and
            then a bird or a person or an animal eats from it, it is regarded as
            a charitable gift for him"(Sahih Bukhari).
          </p>
          <p>
            As long as animals and humans benefit from the tree, the giver will
            receive a continuous reward. So acts like planting a tree, setting
            up a water pump, or managing an income source for a brother will
            benefit the people for a long time. Even if the giver passes away,
            he will still get rewards from Allah for this sadaqah.
          </p>
          <h4 className="fw-bold">
            Importance of managing an income for a Muslim
          </h4>
          <p>
            We should always prefer giving our sadaqah in the most necessary
            cases. There is a section of people who are skilled yet unemployed
            due to fewer work opportunities. On the other hand, there are a huge
            number of people who don't have the training and basic amenities of
            life. The recent covid crisis has made the situation worse for both
            types of people in society.
          </p>
          <p>
            With little or no income they struggle to meet both ends. A huge
            number of lower-middle-class people lost their jobs and incurred
            losses in their businesses during the lockdown.
          </p>
          <p>
            Those who got covid and survived are going through a financial
            crisis as they had to spend vast money to get cured of the virus.
            Many sisters lost their husbands during covid, the only breadwinner
            of the family. As a result, they are now struggling to run the
            family.
          </p>
          <p>
            If such a dire situation is not addressed immediately, it can badly
            affect the families and societies at large. Many families are
            already living in huge debt. Some families are starving for days and
            weeks of not being able to make an income.
          </p>
          <p>
            The worst consequence is a lot of unemployed young people are
            getting involved in unlawful activities and haram income so that
            they can somehow manage money in a short time.
          </p>
          <p>
            Managing an income for them as a sadaqah Jariyah will help them
            immensely and alleviate the dire situation. Here’s how donations can
            help them:
          </p>
          <ul>
            <li>
              They will be able to pay off huge amounts of debts and interest
              associated with it.
            </li>
            <li>
              The donations can be used to give proper education and support to
              those young enthusiastic people who are willing to work outside.
            </li>
            <li>
              It will also let the widow sisters receive training so that they
              could learn handicraft works.
            </li>
            <li>
              Brothers can set up small businesses by managing the capital.
            </li>
            <li>
              Donations could be invested in training institutions to create
              more halal employment for the lower-income people.
            </li>
            <li>
              There are a significant number of people with physical and mental
              disabilities. Proper treatment,support and work opportunities can
              be given with the donation for this class of people.
            </li>
            <li>
              It will prevent the youth from getting engaged in unlawful
              activities and encourage them to earn a halal income.
            </li>
          </ul>
          <p>
            Managing income for these families can provide them with a secure
            and sound life that will benefit generations to come.
          </p>
          <h4 className="fw-bold">
            Rewards for helping your Muslim brothers and sister
          </h4>
          <p>
            By giving this form of charity the donor can earn immense rewards
            and blessings in this world and hereafter. It will increase and
            purify the wealth.
          </p>
          <p>
            Muhammad, upon him be peace, said: “Charity does not in any way
            decrease the wealth and the servant who forgives, Allah adds to his
            respect; and the one who shows humility, Allah elevates him in the
            estimation (of the people).” (Muslim)
          </p>
          <p>
            Those who will give Sadaqah Jariyah, Allah will ease the hardship
            and remove calamities from his life. Most importantly, it will be a
            shade for him on the day of judgment and protect him from hellfire.
          </p>
          <p>
            The Prophet(pbuh) said: “The believer’s shade on the Day of
            Resurrection will be his charity.” (Al-Tirmidhi)
          </p>

          <h4 className="fw-bold">Conclusion</h4>
          <p>
            We cannot completely worship and please Allah without treating his
            creations with the utmost kindness and caring. Even if we look at
            the life of our prophets and the greatest Prophet Muhammad(PBUH), we
            find they never hesitated to serve their Muslim brothers and
            sisters.
          </p>
          <p>
            It is the duty of all Muslims to help those organizations that are
            working in the field to address such problems in society.
          </p>
          <p>
            That’s why Sadaqah-Made-Easy(SME) has launched a platform where you
            can easily find and donate to various sadaqah Jariyah projects.
          </p>
        </div>
      </div>
    ),
  },
];

export function getBlogs() {
  return blogs.filter((o) => o);
}
