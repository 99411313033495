import finshareVdo from "../../vdo/finshare-vdo.mp4";
import finsharebtninteraction from "../../vdo/finsharebtninteraction.mov";
import finshareAuth from "../../img/finshare/finshareAuth.jpg";
import finsharelend1 from "../../img/finshare/finsharelend1.jpg";
import finshareForms from "../../img/finshare/finshareForms.jpg";
import finsharelenderflow from "../../img/finshare/finsharelenderflow.jpeg";
import finsharesysstatus from "../../img/finshare/finsharesysstatus.jpg";
import finsharegamification from "../../img/finshare/finsharegamification.jpg";
import finsharecolors from "../../img/finshare/finsharecolors.jpg";
import finshareothers from "../../img/finshare/finshareothers.jpeg";
import finsharewireframe from "../../img/finshare/finsharewireframe.jpeg";
import finsharepersona from "../../img/finshare/finsharepersona.webp";
import finsharehifi from "../../img/finshare/finsharehifi.webp";
import finsharehomeui from "../../img/finshare/finsharehomeui.webp";
import finshareqr from "../../img/finshare/finshareqr.png";

//last serial: 1.5

const ProjectFirstData = [
  {
    _ID: "sdqme01.1",
    //projectImg: finshareVdo,
    projectVdo: finshareVdo,
    projectHeading: "FinShare - Towards flexible financing",
    projectSubTitle: (
      <>
        FinShare is a mobile app where users can create lend request as a
        borrower and send it to a selected lender. Whereas, lender can accept
        that request after getting notification and mail. Finally, an agreement
        will be generated. My contribution here was to design its user
        experience to help them launch their MVP.
      </>
    ),

    slug: "finshare",
    based: "South Asia",
    tag: "Fintech",
    timeline: <span> 04/09/2022 - 06/29/2022</span>,
    projectDetails: (
      <div className="container">
        <div className="row">
          {/*intro */}
          <div className="col-md-12 mb-5">
            <h3>
              FinShare is a mobile app where users can create lend request as a
              borrower and send it to a selected lender and vice-versa. Whereas,
              lender can accept that request after getting notification via mail
              and select a witness. Finally, an agreement will be generated in
              virtual presence of a selected witness. My contribution here was
              to design its user experience to help them launch their MVP.
              <br />
            </h3>
            <p
              className="text-secondary text-center fw-light mt-5 ps-2 pe-2"
              style={{ fontSize: "22px" }}
            >
              <i>
                To comply with my confidentiality agreement I have omitted and
                appropriated confidential information. These documentation and
                designs are the reinterpretation of the original.
              </i>
            </p>
          </div>

          <div className="col-md-6 col-12 mt-4">
            <h4>
              <u>Problem statement</u>
            </h4>
            <p>
              How can we make an ordinary app where a bridge between lenders and
              borrowers is built and that{" "}
              <b className="fw-bold">
                {" "}
                bridge keeps running through necessary transactions like helping
                a borrower starting business by giving interest-free loan?
              </b>
            </p>
          </div>
          <div className="col-md-6 col-12 mt-4">
            <h4>
              <u>My role</u>
            </h4>
            <ul>
              <li>User research</li>
              <li>Competitive analysis</li>
              <li>Wireframe of the basic flow</li>
              <li>UI design</li>
              <li>Build primary color styles</li>
              <li>Prototyping</li>
            </ul>
          </div>

          {/*auth screens */}
          <div className="col-md-12">
            <img
              src={finshareAuth}
              alt="finshare-authentication-screens"
              className="w-100"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Authentication screens
            </p>
          </div>

          {/*user flow*/}
          <div className="col-md-12" style={{ marginTop: "8rem" }}>
            <p>Understanding the flow</p>
            <h4 style={{ fontSize: "36px" }}>
              Client presented me the pre-documented flow of his solution. In a
              single sentence, the solution is, "FinShare wants to help make a
              bridge between lenders and borrowers through an app with proper
              confidentiality and agreements." I developed the final user flow
              aligning with the presented documentation of flow.
            </h4>
            <p style={{ fontSize: "20px", fontWeight: "normal" }}>
              Context: A Lender wants to help a random borrower from verified
              user list. Here, Lender also uses "Request" to be acknowledged by
              the borrower which is clear that{" "}
              <b className="fw-bold"> the design speaks the users' language.</b>
              <br />
              <br />
              Note: Borrower will be verified by the selected witness of the
              lender.
            </p>
            <img
              src={finsharelenderflow}
              alt="finshare-lender-flow"
              className="w-100"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Lender user flow
            </p>
          </div>

          {/*user persona*/}
          <div className="col-md-12" style={{ marginTop: "8rem" }}>
            <p>User's mental model</p>
            <h4 style={{ fontSize: "36px" }}>
              Client also sent me a file of data where he drafted 15 interview's
              script that has been noted by himself. That file also contained
              user's basic informations. I accumulated qualitative data from
              that file which direclty helped to understand user's mental model
              based on the solution statement I got.
            </h4>

            <img
              src={finsharepersona}
              alt="finshare-persona"
              className="w-100 mt-5"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              User personas
            </p>
          </div>

          {/*lo-fi wireframe*/}
          <div className="col-md-12" style={{ marginTop: "8rem" }}>
            <p>Wireframing</p>
            <h4 style={{ fontSize: "36px" }}>
              To reduce time, I jumped into making low fidelity wireframe. The
              main task was to make the primary flow of creating a lend/borrow
              request by a user. An optimized flow has been developed with the
              help of user flow diagram.
            </h4>
            <p style={{ fontSize: "20px", fontWeight: "normal" }}>
              A consistent experience is set to the basic flow of creating
              request which makes
              <b className="fw-bold">
                {" "}
                user's expectation for future interactions{" "}
              </b>
              with the FinShare itself and builds user confidence.
            </p>
            <img
              src={finsharewireframe}
              alt="finshare-high-fidelity"
              className="w-100"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Low-fidelity wireframe with illustrated basic user task flow
            </p>
          </div>

          {/*mid-fi wireframe*/}
          <div className="col-md-12" style={{ marginTop: "8rem" }}>
            <p>Monochrome skeleton</p>
            <h4 style={{ fontSize: "36px" }}>
              I prepared a mid-fidelity wireframe without implementing brand
              color theme on the element. Keeping all tasks straight away, I
              first created the vital components to reuse iteratively in the
              final design phase. To speed up the evaluation, this decision of
              presenting the core design was highly appreciated in the team
              meeting.
            </h4>

            <img
              src={finsharehifi}
              alt="finshare-high-fidelity"
              className="w-100 mt-5"
            />
            <p
              className="text-center text-secondary mt-3"
              style={{ fontSize: "14px" }}
            >
              Mid-fidelity wireframe without implementing branding color's theme
            </p>
          </div>

          {/*elaborate-lending */}
          <div className="row" style={{ marginTop: "8rem" }}>
            <div className="text-center" style={{ marginBottom: "8rem" }}>
              <h4 className="p-3 border rounded bg-light">
                When a notion is developed, its easy to convince clients with
                the functionality implemented
              </h4>
            </div>

            {/*home screen design rational*/}
            <div className="col-md-12" style={{ marginBottom: "10rem" }}>
              <p>Home screen design - Fundamentals behind</p>
              <h4 style={{ fontSize: "36px" }}>
                After polishing the home screen with colors and components, I
                presented design rational of the home screen which can be viewed
                from the image below
              </h4>

              <img
                src={finsharehomeui}
                alt="finshare-home-design-rational"
                className="w-100 mt-5"
              />
              <p
                className="text-center text-secondary"
                style={{ fontSize: "14px" }}
              >
                Design rational of Home screen
              </p>
            </div>

            <div className="col-md-5 align-self-center">
              <div className="text-left">
                <p>Start of polishing the visual</p>
                <h4 style={{ fontSize: "36px" }}>
                  In the era of digitization, a needy person can avail any
                  reliable opportunity easily. Without any hesitation!
                </h4>
                <p style={{ fontSize: "20px", fontWeight: "normal" }}>
                  Digital communication made it way too faster to initiate a
                  bridge between the one needs help and the one wants to help.
                  It needed in-depth user research to understand the eco-system
                  first which helped me to design an unique experience.{" "}
                  <b className="fw-bold">
                    Build trust through open and continuous communication
                  </b>{" "}
                  <br />- is the key for a fintech app.
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <img
                src={finsharelend1}
                alt="finshare-authentication-screens"
                className="w-100"
              />
              <p
                className="text-center text-secondary mt-5"
                style={{ fontSize: "14px" }}
              >
                Lender is engaging
              </p>
            </div>

            {/*form screens */}
            <div className="col-md-12" style={{ marginTop: "8rem" }}>
              <h4 style={{ fontSize: "36px" }}>
                If a Lender wants to help someone who needs help, he/she needs
                to fill two forms to proceed. New users need to complete the
                profile first. Then he/she will be redirected to the Lender
                form.
              </h4>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "normal",
                  marginBottom: "8rem",
                }}
              >
                FinShare needs users basic information to manipulate the end
                agreement copy which can be downloaded as PDF. In financial
                transaction, filling agreement form is the standard way to
                <b className="fw-bold"> communicate clearly with users</b> per
                se. To make users feel "You are in a reliable platform", form
                doesn't ask irrelevant questions and designed in that way.
              </p>
              <img
                src={finshareForms}
                alt="finshare-forms-screens"
                className="w-100"
              />
              <p
                className="text-center text-secondary mt-5"
                style={{ fontSize: "14px" }}
              >
                Profile & Lender forms
              </p>
            </div>

            {/*system status*/}
            <div className="row" style={{ marginTop: "12rem" }}>
              <div className="col-md-7">
                <img
                  src={finsharesysstatus}
                  alt="finshare-sys-status"
                  className="w-100"
                />
                <p
                  className="text-center text-secondary mt-3"
                  style={{ fontSize: "14px" }}
                >
                  System status components
                </p>
              </div>
              <div className="col-md-5 align-self-center">
                <div className="text-left">
                  <h4 style={{ fontSize: "36px" }}>
                    Users can learn the results of their previous interactions
                    and plan their next moves when they are aware of the
                    system's current status.
                  </h4>
                  <p style={{ fontSize: "20px", fontWeight: "normal" }}>
                    In FinShare, modals help users to understand their current
                    status and to{" "}
                    <b className="fw-bold"> predict interactions </b> which
                    creates trust in the product as well as the brand. The top
                    app bar provides content and actions related to the current
                    screen. It’s used for branding, screen titles, navigation,
                    and actions.
                  </p>
                </div>
              </div>
            </div>

            {/*sign up btn interaction */}

            <div
              className="d-flex flex-column justify-content-center align-items-center"
              style={{ marginTop: "15rem", marginBottom: "10rem" }}
            >
              <video
                autoPlay
                loop
                muted
                width="300"
                style={{
                  clipPath: "inset(2px 2px)",
                }}
              >
                <source src={finsharebtninteraction} type="video/mp4"></source>
              </video>
              <div className="text-center mt-5 mb-4">
                <p>Leveraging Labor Perception Bias</p>
                <h3 style={{ lineHeight: "40px" }}>
                  During signing up, to positively instigate user's high
                  expectation, I added a series of labor animations inside the
                  Sign up button. Users tend to trust and value things more when
                  they see this underlying work of opening a new account. It
                  reduces chances of being skeptical from users perspective as
                  too short opening/waiting time makes users skeptical.
                </h3>
              </div>
            </div>

            {/*gamification*/}
            <div className="row" style={{ marginTop: "8rem" }}>
              <div className="col-md-5 align-self-center">
                <div className="text-left">
                  <h4 style={{ fontSize: "36px" }}>
                    Gameplay elements like badge in a non-gaming app is fun.
                  </h4>
                  <p style={{ fontSize: "20px", fontWeight: "normal" }}>
                    To enhance user engagement and to motivate users, once a
                    lender/borrower completed their first agreement, they get a
                    "First agreement completion badge" in their profile which is
                    public. However, a verified user can also achieve a verified
                    badge. This feature supports{" "}
                    <b className="fw-bold">
                      {" "}
                      the primary goal of both user and app.
                    </b>
                  </p>
                </div>
              </div>
              <div className="col-md-7">
                <img
                  src={finsharegamification}
                  alt="finshare-sys-status"
                  className="w-100"
                />
                <p
                  className="text-center text-secondary mt-3"
                  style={{ fontSize: "14px" }}
                >
                  Gamification element
                </p>
              </div>
            </div>

            {/*color style*/}
            <div className="row" style={{ marginTop: "8rem" }}>
              <div className="d-flex justify-content-around mb-5">
                <div className="flex-color-1 ms-5">
                  <div className="text-left">
                    <p>Lets view color styles now</p>
                    <h4 style={{ fontSize: "36px" }}>
                      Target audiences are Android users - the motivation behind
                      following the Material design system for FinShare app.
                    </h4>
                  </div>
                </div>
                <div className="flex-color-2 align-self-center">
                  <div className="text-left">
                    <p style={{ fontSize: "20px", fontWeight: "normal" }}>
                      To {""}
                      <b className="fw-bold">
                        keep the content and visual design focused
                      </b>{" "}
                      on the essentials, it has been ensured that the visual
                      elements of the interface support the user's primary
                      goals. To avoid interfere with FinShare MVP version's
                      usability, prioritization of the content and features has
                      been well aligned.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <img
                  src={finsharecolors}
                  alt="finshare-color-styles"
                  className="w-100 border"
                />
                <p
                  className="text-center text-secondary mt-3"
                  style={{ fontSize: "14px" }}
                >
                  Color styles & Stickersheet
                </p>
              </div>
            </div>

            {/*other screens */}
            <div className="col-md-12" style={{ marginTop: "8rem" }}>
              <h4 style={{ fontSize: "36px" }}>
                Presenting FinShare's other screens
              </h4>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "normal",
                  marginBottom: "8rem",
                }}
              >
                FinShare needs users basic information to manipulate the end
                agreement copy which can be downloaded as PDF. In financial
                transaction, filling agreement form is the standard way to
                <b className="fw-bold"> communicate clearly with users</b> per
                se. To make users feel "You are in a reliable platform", form
                doesn't ask irrelevant questions and designed in that way.
              </p>
              <img
                src={finshareothers}
                alt="finshare-other-screens"
                className="w-100"
              />
              <p
                className="text-center text-secondary mt-3"
                style={{ fontSize: "14px" }}
              >
                Other screens
              </p>
            </div>
          </div>
        </div>

        {/*proto-qr*/}
        <div
          style={{
            marginTop: "10rem",
          }}
          className="row"
        >
          <div className="col-md-12 col-12 mb-4">
            <h4
              style={{
                fontSize: "28px",
                //lineHeight: "50px",
                textAlign: "left",
              }}
            >
              Scan this QR code to view prototype in Figma
            </h4>
          </div>
          <div className="col-md-6 col-12 text-left">
            <img src={finshareqr} alt="finshare-qr" className="w-50" />
            <p className="fw-bold mt-3" style={{ fontSize: "16px" }}>
              Scan Me
            </p>
          </div>
        </div>

        {/*The impact and lesson learnt */}
        <div
          className="d-flex justify-content-center p-3"
          style={{ marginTop: "5rem" }}
        >
          <div className="flex-div border rounded bg-light shadow-sm p-3 me-3">
            <h2 className="text-center mb-4">
              <u>The impact</u>
            </h2>
            <ul
              style={{
                listStyleType: "none",
                textAlign: "left",
                fontSize: "28px",
                lineHeight: "45px",
              }}
            >
              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>Ready
                to pitch the scaled down version
              </li>

              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>
                Introduced and designed a gamification feature
              </li>
              <li>
                <i className="fa fa-check-circle text-secondary me-3"></i>
                Developers hand-off with technical clarity{" "}
              </li>
            </ul>
          </div>

          <div className="flex-div border rounded shadow-sm p-3">
            <h2 className="text-center mb-4">
              <u>Lesson learnt</u>
            </h2>
            <ul
              style={{
                listStyleType: "none",
                textAlign: "left",
                fontSize: "28px",
                lineHeight: "45px",
              }}
            >
              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>Real life
                use cases of fintech products and how to deal with it
              </li>

              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                People tend to be super reserved in case of data sharing
              </li>
              <li>
                <i className="fa fa-thumbs-up text-secondary me-3"></i>
                People want history menu when its a fintech app
              </li>
            </ul>
          </div>
        </div>

        <p className="text-center" style={{ marginTop: "5rem" }}>
          - A big thank you for the time you spent reading this case study -
        </p>
      </div>
    ),
  },
];

export function getProjectFirstData() {
  return ProjectFirstData;
}
