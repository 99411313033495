/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid
// eslint-disable-next-line jsx-a11y/img-redundant-alt

import travelcircus from "../../img/reviews/travelcircus.png";
import anlytic from "../../img/reviews/anlytic.svg";
import rudro from "../../img/reviews/rudro.png";
import martijn from "../../img/reviews/martijn.png";

import { useLocation } from "react-router-dom";

function Reviews() {
  const location = useLocation();

  //  const { searchProjects, handleSearch } = this.context;

  return (
    <div className="container-fluid reviews">
      <div className="row mt-5">
        <div className="col-md-6 col-12">
          <div className="card border-dark mb-3">
            <img className="card-img-top" src={anlytic} alt="Card image cap" />
            <div className="card-body text-light">
              <p className="card-text">
                Hasan demonstrated not only expertise in UX design. He was
                incredibly dedicated to Anlytic, showing genuine loyalty to the
                company and striving for improvements. He constantly contributed
                ideas to enhance our product, inspire the team, and support
                Anlytic’s growth. When Hasan stepped into a team lead role,
                where he mentored other designers, and proposed loads of
                initiatives, Hasan organized the work environment in a clear way
                and kept improving it.
              </p>

              <div className="review-card-person">
                <div className="review-card-person-info">
                  <img
                    className="person-img"
                    src={martijn}
                    alt="Card image cap"
                  />
                  <div className="review-card-person-text-group">
                    <p className="card-text-bottom text-dark">
                      Martijn Witteveen
                    </p>
                    <p className="card-subt-text-bottom">CEO at Anlytic.com</p>
                  </div>
                </div>
                <div className="review-link ms-2">
                  <a
                    href="https://drive.google.com/file/d/19oQd96l1M8BcoawlVVDbsv_57JA4Nzjp/view?usp=sharing"
                    target="__blank"
                  >
                    <i
                      className="fa fa-sm fa-external-link"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="card border-dark">
            <img
              className="card-img-top"
              src={travelcircus}
              alt="Card image cap"
            />
            <div className="card-body text-light">
              <p className="card-text">
                Hasan worked as a UI designer at our company. During that period
                he was enthusiastic and passionate about his task. I believe he
                will do better with his honest and sincere attitude.
              </p>

              <div className="review-card-person">
                <div className="review-card-person-info">
                  <img
                    className="person-img"
                    src={rudro}
                    alt="Card image cap"
                  />
                  <div className="review-card-person-text-group">
                    <p className="card-text-bottom text-dark">
                      Mohammad Jahidul Alam
                    </p>
                    <p className="card-subt-text-bottom">
                      CEO at Synchronise IT
                    </p>
                  </div>
                </div>
                <div className="review-link ms-2">
                  <a
                    href="https://www.linkedin.com/in/hasansharifrht/details/recommendations/?detailScreenTabIndex=2"
                    target="__blank"
                  >
                    <i
                      className="fa fa-sm fa-external-link"
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
