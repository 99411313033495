/* eslint-disable jsx-a11y/alt-text */
import googlecert from "../../img/cred/googlecert.png";
import infovisual from "../../img/cred/infovisual.png";
import psyecom from "../../img/cred/psyecom.png";
import hci from "../../img/cred/hci.png";
import uxmanage from "../../img/cred/uxmanage.png";
import pmll from "../../img/cred/pmll.png";

function Credentials() {
  return (
    <div className="credentials">
      <p className="mt-2 mb-2 text-left">
        <a
          href="https://www.coursera.org/account/accomplishments/specialization/certificate/PMT5ZYFU3EFX"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <img
            src={googlecert}
            className="rounded credentials__cred-img border me-2"
          />{" "}
        </a>
        <a
          href="https://www.interaction-design.org/hasan-sharif/certificate/course/68e681d7-5c2e-4bf4-8cb7-b5577d52b066"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <img
            src={infovisual}
            className="rounded credentials__cred-img border me-2"
          />{" "}
        </a>
        <a
          href="https://www.interaction-design.org/hasan-sharif/certificate/course/144b000a-ae03-4afd-adc5-0a4c942b0f31"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <img
            src={psyecom}
            className="rounded credentials__cred-img border me-2"
          />{" "}
        </a>
        <a
          href="https://www.interaction-design.org/hasan-sharif/certificate/course/d3492b03-4edd-4930-ad45-d56d7193a721"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <img
            src={hci}
            className="rounded credentials__cred-img border me-2"
          />{" "}
        </a>
        <a
          href="https://www.interaction-design.org/hasan-sharif/certificate/course/f7b88d6e-ed1e-40b3-9090-594c619dfef2"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <img
            src={uxmanage}
            className="rounded credentials__cred-img border me-2"
          />{" "}
        </a>
        <a
          href="https://www.linkedin.com/learning/certificates/8020e2eb73be0cacd6deeb93405fc2326f331f5a0c772297e786dda923947749"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <img
            src={pmll}
            className="rounded credentials__cred-img border me-2"
          />{" "}
        </a>
      </p>
    </div>
  );
}

export default Credentials;
