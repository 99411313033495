import { Parallax } from "react-scroll-parallax";
import casestudyimg from "../../../img/bikedot/casestudyimg.png";

function Left() {
  return (
    <>
      <Parallax speed={-10}>
        <div className="fast attention-one-left">
          <a href="https://hasansharif.com/project/bikedot" target="__blank">
            <div className="card text-center mt-3 p-0">
              <div className="card-body">
                <h2 className="card-title">
                  <i className="fa fa-book" aria-hidden="true"></i> &nbsp; Case
                  study
                  <span className="card-date">APR. 2020</span>
                </h2>
                <h3 className="card-text text-center">
                  LEAN UX <br />
                  APPLIED
                </h3>
              </div>
              <div className="card-bottom-img">
                <img src={casestudyimg} alt="casestudyimg" />
              </div>
              {/*<div className="card-footer">
              <a
                className="text-light"
                href="https://play.google.com/store/apps/details?id=com.sadaqahmadeeasy.app"
                target="__blank"
              >
                Read now
              </a>
            </div>*/}
            </div>
          </a>
        </div>
      </Parallax>
    </>
  );
}
export default Left;
