/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
// eslint-disable-next-line jsx-a11y/anchor-is-valid

//import warningred from "../../img/gif/warningred.gif";

import React from "react";
import Fluid from "./Fluid";
import Left from "./Left";
import Right from "./Right";
import Middle from "./Middle";

function Attention() {
  return (
    <div className="attention">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12">
            <Fluid />
          </div>
          <div className="container">
            <div className="row">
              <Middle />

              <Left />

              <Right />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Attention;
