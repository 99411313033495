import { useContext } from "react";
import { Link } from "react-router-dom";
import { StateContext } from "../../Context/StateProvider";

function ProductList() {
  const { firstProduct } = useContext(StateContext);
  const { secondProduct } = useContext(StateContext);

  return (
    <div>
      <div className="zakat">
        {/*first project - sme*/}
        <div className="container" style={{ marginTop: "15rem" }}>
          {firstProduct.map((productOne) => (
            <div className="row ramadan__project-row" key={productOne._ID}>
              <div className="col-md-6 col-12 mb-5">
                <div className="ramadan__project-card ramadan__project-card_2">
                  {" "}
                  <div className="ramadan__project-div-2">
                    <video
                      autoPlay
                      loop
                      muted
                      width="444"
                      height="662"
                      className="ramadan__project-vdo ramadan__project-vdo-2 project_vdo_sm"
                    >
                      <source
                        src={productOne.projectVdo}
                        type="video/mp4"
                      ></source>
                    </video>
                    <img
                      src={productOne.projectImg}
                      className="ramadan__project-img_2 project_vdo_sm"
                      alt="project-img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12 align-self-center">
                <h1 className="ramadan__title">{productOne.projectHeading}</h1>
                <h4 className="ramadan__subTitle">
                  {productOne.projectSubTitle}
                </h4>
                <Link
                  to={`/product/${productOne.slug}`}
                  className="ramadan__project-link"
                >
                  {" "}
                  <button className="btn btnCTA ramadan__project-btn">
                    Continue reading
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>

        {/*second project - machbazaar*/}
        <div className="container" style={{ marginTop: "10rem" }}>
          {secondProduct.map((productTwo) => (
            <div
              className="row ramadan__project-row align-items-center"
              key={productTwo._ID}
            >
              <div className="col-md-6 col-12 ">
                <h1 className="ramadan__title">{productTwo.projectHeading}</h1>
                <h4 className="ramadan__subTitle">
                  {productTwo.projectSubTitle}
                </h4>
                <Link
                  to={`/product/${productTwo.slug}`}
                  className="ramadan__project-link"
                >
                  {" "}
                  <button className="btn btnCTA ramadan__project-btn">
                    Continue reading
                  </button>
                </Link>
              </div>
              <div className="col-md-6 col-12 mb-5" key={productTwo._ID}>
                <div className="ramadan__project-card">
                  <img
                    src={productTwo.projectImg}
                    alt="measurer-pro-cover"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProductList;
