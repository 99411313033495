import eightonboardingdark from "../../../img/eight/eightonboardingdark.png";
//import anlyticuserguide from "../../../vdo/anlyticuserguide.mp4";

function Middle() {
  return (
    <div className="col-12 col-md-4 attention-two-right">
      <a
        href="https://drive.google.com/file/d/1fr2owu2V9FvVByMiuul8r9zZcObAwywT/view?usp=sharing"
        target="__blank"
      >
        <div className="card text-start mt-5 p-0">
          <div className="card-body">
            <h4 className="card-title" style={{ color: "#cccccc" }}>
              <i className="fa fa-book" aria-hidden="true"></i> &nbsp;Can a
              simple design help users?
            </h4>
            <h4 className="card-text text-start fw-light text-light">
              Good onboarding = Good convertion
            </h4>

            {/*<p style={{ color: "#c6923a" }} className="text-end">
              {" "}
              <i className="fa fa-youtube" aria-hidden="true"></i> Check from youtube
            </p>*/}

            <div className="card-bottom-img">
              <img src={eightonboardingdark} alt="user-onboarding" />
              {/*<video
                autoPlay
                loop
                muted
                width="420"
                height="230"
                className="project_vdo_mid"
                style={{ borderRadius: "8px" }}
              >
                <source src={anlyticuserguide} type="video/mp4"></source>
              </video>*/}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
export default Middle;
